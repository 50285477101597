body {
  padding: 8vh;
}

.navContainer {
  width: 100%;
  background-color: #f8f9fa !important;
}

.footer {
  position: relative; /* Most important setting for sticky footer */
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}
