@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  font-family: Montserrat;
  box-sizing: border-box;
  color: #333;
}

body {
  font-size: 16px;
}

.navContainer {
  width: 100%;
  background-color: #f8f9fa !important;
  margin-bottom: 10px;
}

.footer {
  position: relative; /* Most important setting for sticky footer */
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.inputGroup {
  margin-bottom: 1rem;
}

label {
  font-size: 0.9rem;
}

input {
  border: none;
  padding: 0.5rem 0.1rem;
  width: 100%;
  border-radius: 2px;
  border-bottom: 1px solid #eee;
  transition: all 0.5s;
}

input:focus {
  border-bottom: 1px solid #ccc;
}

button {
  background: #f7c52a;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 2px;
  width: 100%;
  text-transform: uppercase;
}

.chatrooms {
  margin-top: 1rem;
}

.chatroom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.join {
  background: #f7c52a;
  padding: 0.25rem 1rem;
  border-radius: 2px;
}

a {
  text-decoration: none;
  color: #333;
}
