


.container-fluid {
    padding: 5px;
  }
  
  .after-about{
      background-color: black;
  }

  .after-about-body{
      color: white;
      size: 55px;
  }



  body, html {
    height: 100%
  }
  
 
  
  /* Position text in the top-left corner */
  .topleft {
    position: absolute;
    top: 0;
    left: 16px;
  }
  
  /* Position text in the bottom-left corner */
  .bottomleft {
    position: absolute;
    bottom: 0;
    left: 16px;
  }
  
  /* Position text in the middle */
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: antiquewhite !important;
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Style the <hr> element */
  hr {
    margin: auto;
    width: 40%;
  }

  @media only screen and (min-width: 1024px) {
    h1 {
    font-size: calc(60px + 0.75vw) !important;
    }

    h4 {
      font-size: calc(14px + 0.75vw) !important;
      font-weight: lighter;
      }

      h5 {
        font-size: calc(14px + 0.75vw) !important;
        font-weight: lighter;
        }

  };